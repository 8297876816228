<template>
  <div id="app">
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" to="/">E_Sukmean's</b-navbar-item>
      </template>
   </b-navbar>
    <router-view></router-view>
  </div>
</template>

<style>
  @import url(//fonts.googleapis.com/css?family=Ubuntu:300);
  @import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
  *{font-family:'ubuntu','Nanum Gothic' !important}
  
  #app > .navbar {
    background: #009688; 
  }
  #app > .navbar * {
    color: rgba(255,255,255,.84);
    font-size: 30px
  }
  html { 
    background: #EEE
  }
</style>
<script>
export default {

}
</script>
